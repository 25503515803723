import React from 'react'

export default function Custom404() {

	return (
		<div>
			{/* <NextSeo title={`404 -Litto`} /> */}

			<div className="mb-32 mt-36 flex flex-col items-center">
				<p className="mb-2 text-3xl font-bold leading-8">
					Oh, well. Nothing to see here.
				</p>
				<p className="max-w-2xl text-center text-md leading-6">
					Move along, explorer. The page you were looking for does not appear to
					exist. It’s possible that the URL was mistyped, the page may have been
					removed or there’s some other kind of technical error. In any case,
					you should leave this page.
				</p>
			</div>
			
		</div>
	);
}


